


















































































































































































import Vue from 'vue'
import Component from 'vue-class-component';
import Breadcrumb from '@/components/common/Breadcrumb.vue';
import Widget from '@/components/common/Widget.vue';
import DataTable from '@/components/table/DataTable.vue';
import store from '../../store';
import api from '../../api';
import { Watch } from 'vue-property-decorator';


declare const $:any;

@Component({components: {Breadcrumb, Widget, DataTable}})
export default class AgencySettings extends Vue
{
  tableColums = [
    {
      label: "ID",
      prop: "customernumber",
      bodyCellClass: 'text-left',
      sortable: true
    },
    {
      label: "Kanzleiname",
      prop: "companyname",
      bodyCellClass: 'text-left',
      sortable: true
    },
    {
      label: "Title",
      prop: "contact_title",
      bodyCellClass: 'text-left',
      sortable: true
    },
    {
      label: "Nachname",
      prop: "contact_lastname",
      bodyCellClass: 'text-left',
      sortable: true
    },
    {
      label: "Vorname",
      prop: "contact_firstname",
      bodyCellClass: 'text-left',
      sortable: true
    },
    {
      label: "Email",
      prop: "contact_email",
      bodyCellClass: 'text-left',
      sortable: true
    },
    {
      label: "Straße",
      prop: "street",
      bodyCellClass: 'text-left',
      sortable: true
    },
    {
      label: "PLZ",
      prop: "zip",
      bodyCellClass: 'text-left',
      sortable: true
    },
    {
      label: "Stadt",
      prop: "city",
      bodyCellClass: 'text-left',
      sortable: true
    },
    {
      label: "Land",
      prop: "country",
      bodyCellClass: 'text-left',
      sortable: true
    },
    {
      label: "Telefon",
      prop: "phone",
      bodyCellClass: 'text-left',
      sortable: true
    },
    {
      label: "Fax",
      prop: "fax",
      bodyCellClass: 'text-left',
      sortable: true
    },
  ]
  perPage = 25;
  orderby = "id";
  orderDirection = "asc";
  totalItems = 0;
  offsetPage = 0;
  tableRows: any[] = []
  user = {
    companyname: '',
    contact_title: '',
    contact_firstname: '',
    contact_lastname: '',
    contact_email: '',
    street: '',
    zip: '',
    city: '',
    country: '',
    phone: '',
    fax: '',
    exportactive: false,
    exporteo: false,
    active: false
  }
  userId = ''
  loadedAgencies: any = {}
  agencies : any[] = []
  // agency = ''
  clients : any[] = []
  client = ''

  constructor() {
    super()

    // this.getOwnStbs()
    this.getAgencies()
  }

  // get clients() {
  //     return store.getters.clients
  // }

  // get client() {
  //     return store.getters.selectedClient
  // }
  get agency() {
      return store.getters.selectedAgency
  }
  getAgencies(){
    api.getConfigClientMandators().then(res => {
      this.loadedAgencies = res.data
      let getAgencies = Object.keys(res.data)
      getAgencies.sort()
      this.agencies   = getAgencies
      if(this.agency == null){
        store.dispatch('updateSelectedAgency', getAgencies[0])
      }
    })
  }

  getOwnStbs() {
    this.tableRows = []
    store.dispatch('updateLoading', store.state.loading >= 0 ? store.state.loading + 1 : 1)
    api.getOwnStbs().then(res => {
      store.dispatch('updateLoading', store.state.loading - 1)
      res.data.forEach((d: any) => {
        this.tableRows.push({
          customernumber: d[0],
          companyname: d[1]
        })
      })
    }).catch(err => {
      store.dispatch('updateLoading', store.state.loading - 1)
    })
  }

  setMandator(client: any) {
      localStorage.setItem('client', JSON.stringify(client))
      this.client = client
      store.dispatch('updateSelectedClient', client)
  }
  setAgency(agency: any) {
      localStorage.setItem('agency', JSON.stringify(agency))
      store.dispatch('updateSelectedAgency', agency)
  }

  @Watch('agency')
  onAgencyChange() {
    if(this.agency==null){
      return;
    }
    let selectedAgency = this.agency
    this.clients    = this.loadedAgencies[selectedAgency]
    this.client     = this.clients[0] || ''
  }

  addModal() {
    this.user = {
      companyname: '',
      contact_title: '',
      contact_firstname: '',
      contact_lastname: '',
      contact_email: '',
      street: '',
      zip: '',
      city: '',
      country: '',
      phone: '',
      fax: '',
      exportactive: false,
      exporteo: false,
      active: false
    }
    this.userId = ''
    $('#add_modal').modal()
  }

  edit(event: any) {
    this.userId = event.customernumber
    store.dispatch('updateLoading', store.state.loading >= 0 ? store.state.loading + 1 : 1)
    api.getOwnStb(this.userId).then(res => {
      store.dispatch('updateLoading', store.state.loading - 1)
      this.user = res.data
    }).catch(err => {
      store.dispatch('updateLoading', store.state.loading - 1)
    })
    $('#add_modal').modal()
  }

  changePermission(event: any) {
    this.user = event
    this.userId = event.customernumber
    $('#permission_modal').modal()
  }

  deleteItem(event: any) {
    this.tableRows.splice(this.tableRows.findIndex(row => row.id === event.id), 1)
  }

  add() {
    if (this.userId) {
      store.dispatch('updateLoading', store.state.loading >= 0 ? store.state.loading + 1 : 1)
      api.updateOwnStb(this.userId, this.user).then(res => {
        store.dispatch('updateLoading', store.state.loading - 1)
        this.getOwnStbs()
      }).catch(err => {
        store.dispatch('updateLoading', store.state.loading - 1)
      })
    } else {
      this.tableRows.push({
        id: '22222',
        ...this.user
      })
    }
  }
  clearOnEsc() {
      //A way to handle Jquery component inside Vuejs
      const _bodySelector =  document.querySelector('body')
      if(_bodySelector){
      _bodySelector.click()
      }
  }

  changeSortDirection(val: any) {
    this.orderDirection = val;
  }

  changeSortProperty(val: any) {
    this.orderby = val;
    this.orderDirection = 'asc';
  }

  changePerPage(val: any): void {
    if (val) {
      let offsetPage = val - 1;
      if (offsetPage !== this.offsetPage) {
        this.offsetPage = offsetPage;
      }
    }
  }

  mounted() {
    document.body.addEventListener('keyup', e => {
      if (e.keyCode === 27) {
        this.clearOnEsc()
      }
    })
  }
}
